@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rowdies&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
  color: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Customize your main colors in :root variables */

:root {
  --main-background-color: #15151b;
  --main-background-image: url(/static/media/meow_bg4.3ee65efa.jpg);
  --card-background-color: #51576D80;
  --card-background-lighter-color: #7A7F92;
  --main-text-color:#F7F6F4;
  --menu-text-color:#181b26;
  --title-text-color:#ffffff;
  margin-top:-20px;
}

body{
  background-color:#15151b;
  background-color:var(--main-background-color);
  background-image:url(/static/media/meow_bg4.3ee65efa.jpg);
  background-image:var(--main-background-image);
  font-family: 'Patrick Hand', cursive;
  font-size: 1.2em;
}

h2, p{
  color: #F7F6F4;
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}

.wallet-adapter-button{
	margin-top:7px !important;
}

#tandc{
	color:white;
	text-decoration:underline !important;
	font-family: 'Quicksand', sans-serif !important;
    font-weight: 400;
    font-size: 15px;
	font-style: italic;
}

#aaa{
	width:100%;
	height:120px;
	background-color: #ffffff;
}
#bbb{
  font-size: 36px;
  line-height: 1.1;
  font-weight: 600!important;
  font-family: 'Rowdies', cursive!important;
  color: #181b26 !important;
  text-decoration: none !important;
}
#bbx{
 color:pink;
}
.MuiChip-root{
	margin-top:-10px !important;
	background-color: #1ebbc0 !important;
}

@media only screen and (max-width: 480px) {
  ul {
	  padding: 0px !important;
	  margin-top: 0px !important;
	  margin-bottom: 0px !important;	  
  }
  li {
	margin: 0 auto !important;  
    float: left !important;
  }
  #aaa{
	height:150px;
  }
  #aaa + div > div:first-child{
	margin-top: -192px;
  }
  #aaa + div > div:first-child >div img{
	content:url('https://live.feralfantasy.io/logo_ff4.png');
  }  
  #aaa + div {
	 margin-top: 60px; 
  }
  #aaa + div > h1{
	 margin: 10px auto !important;
  }
  #aaa + div > h1 + div >div >div > h2 {
	  margin-top:0px !important;
	  margin-bottom:0px !important;
  }
  #aaa + div > h1 + div >div >div > br {
	  display:none;
  }
}
